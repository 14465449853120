import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import ContactUs from './components/ContactUs';
import Packages from './components/Packages';
import BurgerMenu from './components/BurgerMenu';
import './Styles.css';
import './App.css';

function App() {
  return (

      <Router>
         <BurgerMenu />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/contact" element={<ContactUs />} />
                <Route path="/packages" element={<Packages />} />
            </Routes>
      </Router>
  );
}

export default App;
