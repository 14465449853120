import oillamp from './oil-lamp-india-svgrepo-com.svg';
import React from 'react';
import '../Styles.css';


const Packages = () => {
    return (
        <div>
            <header className='Packages-header'>
                <p>
                    Packages
                </p>
                <img src={oillamp} className="App-logo-oillamp" alt="oillamp" />
            </header>
            <h1 className="Packages-header1">
                <p>
                    (Package details to go here)
                </p>
            </h1>
        </div>
    );
};

export default Packages;