import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const BurgerMenu = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div>
            <button onClick={toggleMenu} className="burger-button">
                ☰
            </button>
            {isOpen && (
                <div className="burger-menu">
                    <Link to="/" onClick={toggleMenu}>Home</Link>
                    <Link to="/contact" onClick={toggleMenu}>Contact Us</Link>
                    <Link to="/packages" onClick={toggleMenu}>Packages</Link>
                </div>
            )}
        </div>
    );
};

export default BurgerMenu;
