import logo2 from './traffic-cone-svgrepo-com.svg';
import oillamp from './oil-lamp-india-svgrepo-com.svg';
import garland from './celebration-flag-garland-svgrepo-com.svg';
import React from 'react';
import '../Styles.css';


function Home() {
  return (
    <div>
      {/* <header className="Home-header">
  <img src={garland} className="garland-left" alt="garland" />
  <p>
    Belfast Indian Catering<br />
    <span className="centered-text">Company</span>
  </p>
  <img src={garland} className="garland-right" alt="garland" />
  <img src={oillamp} className="App-logo-oillamp" alt="oillamp" />
</header> */}
<header className="Home-header">
  <div className="header-content">
    <img src={garland} className="garland-left" alt="garland" />
    <p>
      Belfast<br /> Indian Catering<br />
      <span className="centered-text">Company</span>
    </p>
    <img src={garland} className="garland-right" alt="garland" />
  </div>
  <img src={oillamp} className="App-logo-oillamp" alt="oillamp" />
</header>
      <h1 className="Home-header1">
        <p>
          (Site under construction)
        </p>
        <img src={logo2} className="App-logo" alt="logo2" />
      </h1>
    </div>
  );
};

export default Home;